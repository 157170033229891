<template>
	<div  :class="$vuetify.breakpoint.name === 'lg' ? 'pr-5 pl-5' : 'px-8'">

		<Confirmation @action-delete="deleted()"/>

		<!-- DIALOG FILTER -->
		<v-dialog v-model="dialog.filter" scrollable persistent max-width="300px">
			<v-card class="border-radius box-shadow">
				<v-card-title class="body-1 text-first d-flex mb-4" style="background-color: #F05326;height:50px">
					<span class="white--text">
						Dialog Filter
					</span>
					<v-spacer></v-spacer>
					<v-icon size="20" @click="dialog.filter = false" color="white">mdi-close-circle</v-icon>
				</v-card-title>
				<v-card-text style="height: 300px;" class="py-5">
					<div class="mb-2">Status</div>
					<v-select
						dense
						outlined
						color="#F05326"
						placeholder="Pilih"
						item-text="text"
						item-value="value"
						:items="status">
					</v-select>
					<div class="mb-2">Level</div>
					<v-select
						dense
						outlined
						color="#F05326"
						placeholder="Pilih"
						item-text="text"
						item-value="value"
						:items="level">
					</v-select>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						small
						width="70"
						elevation="0"
						class="text-capitalize"
						:disabled="$store.state.process.run"
						@click="dialog.filter = false">
						Reset
					</v-btn>
					<v-btn
						small
						width="70"
						color="#EF6C00"
						elevation="0"
						:disabled="$store.state.process.run"
						:loading="$store.state.process.run"
						class="white--text text-capitalize"
						@click="toFilter()">
						Filter
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<div class="mt-5">
			<div class="title black--text mb-2">List Order</div>

			<!-- <Breadcrumbs :title="`Add New Questions`" :items="items"/> -->
			<Snackbar ref="snackbar"/>

			<v-row justify="space-between" class="mt-2" v-if="!$store.state.process.run || searching != ''">
				<v-col cols="4" v-if="!$store.state.process.run || searching != ''">
					<div>
						<v-text-field
							solo
							filled
							dense
							flat
							:hide-details="true"
							placeholder="Search Order . . ."
							autocomplete="off"
							v-model="searching"
							clearable
							color="#F05326"
							@click:clear="searching = '';"
							prepend-inner-icon="mdi-magnify">
						</v-text-field>
					</div>
				</v-col>
				<v-col cols="6" class="text-right">
					<div class="d-flex float-right" v-if="!$store.state.process.run && list.total_data > 0">
						<v-btn 
							depressed
							:loading="process.run"
							@click="showDialogGenerate()"
							elevation="0"
							color="#F05326" 
							class="text-capitalize rounded-lg white--text">
							Generate voucher
						</v-btn>
					</div>
				</v-col>
			</v-row>

			<div class="text-center mt-7" v-if="$store.state.process.run == false && list.total_data < 1">
				<div class="mt-5">
					<v-img :src="require('@/assets/img/empty.png')" width="200" class="mx-auto"></v-img>
				</div>
				<div class="mt-3">Please generate new voucher</div>
				<div class="mt-2">by click the button below</div>
				<v-btn
					color="#F05326"
					depressed
					dense
					@click="showDialogGenerate()"
					class="mt-3 white--text text-capitalize">
					Generate new voucher
				</v-btn>
			</div>

			<div v-if="$store.state.process.run" class="mt-3 mb-8">
				<v-skeleton-loader
					class="ma-auto"
					:loading="$store.state.process.run"
					:tile="false"
					transition="scale-transition"
					type="table">
				</v-skeleton-loader>
			</div>

			<div class="mt-3 mb-8" v-if="!$store.state.process.run && list.total_data > 0">
				<v-data-table
					class="table-list"
					hide-default-footer
					fixed-header
					:loading="$store.state.process.run"
					:headers="headers"
					:items="list.list"
					:items-per-page.sync="list.limit"
					:page.sync="list.page"
					:server-items-length="list.total_data"
					item-key="id"
					:footer-props="{
						itemsPerPageText : 'Data per halaman',
						'items-per-page-options': [5,10, 20, 50, 100, 200]
					}"
					>
					<template slot="footer" slot-scope="{ props }">
						<Pagination 
							@reload="fetch(page)"
							@changeLimit="limit = $event; fetch()" 
							@changePage="page = $event; fetch($event)" 
							:model="props"/>
					</template>
					<template v-slot:[`item.id`]="{ item }">
						<v-btn text :to="`/order/license/${item.id}`" class="text-title">
							<div style="border-bottom: dashed 1px blue">
								{{ item.id }}
							</div>
						</v-btn>
					</template>
					<template v-slot:[`item.total`]="{ item }">
						<div class="text-capitalize">{{ item.total }}</div>
					</template>
					<template v-slot:[`item.total_sold`]="{ item }">
						<div class="text-capitalize">{{ item.total_sold }}</div>
					</template>
					<template v-slot:[`item.total_used`]="{ item }">
						<div class="text-capitalize">{{ item.total_used }}</div>
					</template>
					<template v-slot:[`item.created_at`]="{ item }">
						<div class="text-capitalize">{{ item.created_at | datetime }}</div>
					</template>
				</v-data-table>
			</div>
		</div>
		<DialogGenerateLicense ref="dialogGenerateLicense"></DialogGenerateLicense>
	</div>
</template>

<script>
import { get, post, put , destroy } from '@/service/Axios'
import Pagination from '@/components/Pagination.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Snackbar from '@/components/Snackbar.vue'
import Confirmation from '@/components/Confirmation.vue'
import DialogGenerateLicense from './components/DialogGenerateLicense.vue'

export default {
	components: {
		Pagination,
		Breadcrumbs,
		Snackbar,
		Confirmation,
		DialogGenerateLicense
	},
	data() {
		return {
			searching: "",
			limit: 10,
			page: 1,
			selected: {
				pagination: 0
			},
			formFilter : {
				member_id: "",
				license: "",
				code: "",
				order_id: "",
				is_used: "",
				is_sold: ""
			},
			items: {
				total: 10
			},
			status: [
				{ text: "Draft" , value: "draft" },
				{ text: "Published" , value: "published" }
			],
			level: [
				{ text: "Easy" , value: "easy" },
				{ text: "Medium" , value: "medium" },
				{ text: "Advance" , value: "advance" }
			],
			headers: [
				{ text: 'Order Name', align: 'start', value: 'id', sortable: false  },
				{ text: 'Total Order', align: 'center', value: 'total', sortable: false  },
				{ text: 'Total Sold', align: 'center', value: 'total_sold', sortable: false  },
				{ text: 'Total Used', align: "center",value: 'total_used', sortable: false  },
				{ text: 'Created at', align: "center",value: 'created_at', sortable: false  },
			],
			list: {},
			dialog: {
				filter: false,
			},
			process: {
				run: false
			},
		}
	},
	watch:{
		'selected.pagination': function(val) {
			this.fetch(val+1)
		},
		searching: _.debounce(function(newVal) {
			this.searching = newVal;
			this.fetch()
		}, 500),
	},
	created() {
		this.fetch()
	},
	methods: {
		filter(){
			this.$refs.snackbar.open("#4CAF50", `Mohon Maaf Fitur Belum Tersedia`);
		},
		async fetch(paging) {
			this.$store.state.process.run = true;
			this.$set(this.items, "list", []);
			await get("license/v1/superuser/list", {
				params: {
					page: paging,
					limit: this.limit,
					sort: "created_at",
					dir: "desc",
					q: this.searching
				}
				}).then((response) => {
					let res = response.data
					if (res.status == 200) {
						this.$store.state.process.run = false;
						this.list = res.data;
					}else{
						this.$store.state.process.run = false;
					}
				});
		},
	 	showDialogGenerate() {
			this.$refs.dialogGenerateLicense.dialogShow()
		},
	}
}
</script>

<style>
.v-small-dialog__actions .v-btn .v-btn__content {
	color: #F05326
}
</style>